<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <b-message
        v-if="showInformation()"
        title="Info"
        type="is-info"
        has-icon
        aria-close-label="Close message"
      >
        <strong>Menu Keterampilan PPDH</strong> dikhususkan untuk mencatat
        aktivitas katerampilan yang memiliki target pencapaian. Untuk mencatat
        aktivitas lain seperti mengikuti kuliah umum, jurnal reading, seminar,
        webinar dan ilmiah sebagai peserta (non presenter) dan aktivitas lain
        yang tidak diberikan target pencapaian dapat diinputkan pada
        <strong>Menu Kegiatan</strong>.
      </b-message>
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">KASUS</p>
          <router-link
            v-if="isPSPD"
            :to="{ name: 'aktivitas-add' }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="plus" />
          </router-link>
          <a
            v-if="canVerifikasi && aktivitasLength > 0"
            class="card-header-icon"
            @click.stop.prevent="saveObjects()"
          >
            <b-icon icon="save" size="is-small" class="has-text-light" />
          </a>
        </header>
        <div class="card-content table-card-content">
          <b-field
            position="is-right"
            class="filter-field"
            grouped
            group-multiline
          >
            <generic-filter-select
              v-if="isPSPD"
              class="filter3"
              apiPath="/matakuliah/options/"
              field="mata kuliah"
              v-model="filterMap.mata_kuliah"
            ></generic-filter-select>
            <generic-filter-select
              v-else
              class="filter3"
              apiPath="/stase/options/"
              field="rotasi"
              v-model="filterMap.stase"
            ></generic-filter-select>
            <verifikasi-select
              class="filter2"
              v-model="filterMap.verifikasi"
            ></verifikasi-select>
            <cari-input class="filter3" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="aktivitasList"
            :mobile-cards="false"
          >
            <b-table-column label="No" v-slot="props">{{
              pagination.startRowNum + props.index
            }}</b-table-column>
            <b-table-column label="Aktivitas" v-slot="props">
              <span class="tanggal">{{ props.row.tanggal }}</span>
              <br />
              <router-link
                :to="{ name: detailNamedURL, params: { id: props.row.id } }"
              >
                <!-- <b-icon class="small-icon" size="is-small" icon="video" /> -->
                {{ props.row.kompetensi }}
              </router-link>
              <br />
              <span v-if="!isPSPD">
                {{ props.row.pspd }}
                <br />
              </span>
              <small
                >{{ props.row.mata_kuliah }} -
                {{ props.row.preceptor }}</small
              >
              <!-- </template> -->
            </b-table-column>
            <b-table-column label="D.T.P" v-slot="props">
              <div v-if="props.row.diagnosis_set.length > 0">
                <b-field grouped group-multiline>
                  D :
                  <komponen-aktivitas-tag
                    v-for="ak_diag in props.row.diagnosis_set"
                    :key="ak_diag.id"
                    :id="ak_diag.id"
                    :namedUrl="'aktivitas-diagnosis-detail'"
                    :aktivitas="props.row"
                    :aktKomp="ak_diag"
                    :tagColor="
                      getTagColor(props.row.pspd_id, props.row.preceptor_id)
                    "
                  />
                </b-field>
                <hr v-if="hasNextAktivitasKomp('D', props.row)" />
              </div>

              <div v-if="props.row.pemeriksaan_set.length > 0">
                <b-field grouped group-multiline>
                  T :
                  <komponen-aktivitas-tag
                    v-for="ak_pem in props.row.pemeriksaan_set"
                    :key="ak_pem.id"
                    :id="ak_pem.id"
                    :namedUrl="'aktivitas-pemeriksaan-detail'"
                    :aktivitas="props.row"
                    :aktKomp="ak_pem"
                    :tagColor="
                      getTagColor(props.row.pspd_id, props.row.preceptor_id)
                    "
                  />
                </b-field>
                <hr v-if="hasNextAktivitasKomp('P', props.row)" />
              </div>

              <div v-if="props.row.tatalaksana_set.length > 0">
                <b-field grouped group-multiline>
                  P :
                  <komponen-aktivitas-tag
                    v-for="ak_tata in props.row.tatalaksana_set"
                    :key="ak_tata.id"
                    :id="ak_tata.id"
                    :namedUrl="'aktivitas-tatalaksana-detail'"
                    :aktivitas="props.row"
                    :aktKomp="ak_tata"
                    :tagColor="
                      getTagColor(props.row.pspd_id, props.row.preceptor_id)
                    "
                  />
                </b-field>
              </div>
            </b-table-column>
            <b-table-column
              v-if="isPreceptor"
              field="verifikasi"
              label="Verifikasi"
              v-slot="props"
            >
              <template
                v-if="includes(props.row.id, 'verifikasi') && canVerifikasi"
              >
                <b-select v-model="payLoadData[props.row.id].verifikasi">
                  <option value="Belum">Belum</option>
                  <option value="Ya">Ya</option>
                  <option value="Tidak">Tidak</option>
                </b-select>
              </template>
              <hasil-mark v-else :hasil="props.row.verifikasi"></hasil-mark>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { clone, has } from "lodash";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import AktivitasList from "../models/aktivitasList.js";

export default {
  name: "AktivitasList",
  components: {
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    HasilMark: () => import("@/apps/core/components/HasilMark.vue"),
    VerifikasiSelect: () =>
      import("@/apps/aktivitas/components/VerifikasiSelect.vue"),
    KomponenAktivitasTag: () =>
      import("@/apps/aktivitas/components/KomponenAktivitasTag.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue"),
  },
  data() {
    this.aktivitasListMdl = new AktivitasList();
    this.listMdl = this.aktivitasListMdl; // alias untuk digunakan di paginationmixin
    let obv = this.aktivitasListMdl.getObservables();
    obv.detailNamedURL = "aktivitas-detail";
    obv.canVerifikasi = false;
    return obv;
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    ...mapGetters("accounts", ["isPSPD", "isPreceptor"]),
    aktivitasLength() {
      return this.aktivitasList.length > 0;
    },
  },
  methods: {
    includes(id) {
      if (has(this.aktivitasBelumSet, id)) {
        return this.aktivitasBelumSet[id];
      }
      return null;
    },
    showInformation() {
      const today = new Date();
      const end_date = new Date("2020-07-13");
      return today < end_date;
    },
    needConfirmation() {
      let vals = Object.values(this.payLoadData);
      let changed = vals.reduce(
        (res, val) => res || val.verifikasi !== "Belum",
        false
      );
      return changed;
    },
    filter() {
      this.aktivitasListMdl.applyFilter();
      this.canVerifikasi =
        this.isPreceptor && this.filterMap.verifikasi == "Belum";
    },
    applyFilter() {
      if (this.needConfirmation()) {
        this.showConfirmationDlg(() => {
          this.saveObjects(this.filter);
        });
      } else {
        this.filter();
      }
    },
    hasNextAktivitasKomp(jenisKomp, row) {
      let kompLenArr = [
        row.diagnosis_set.length,
        row.pemeriksaan_set.length,
        row.tatalaksana_set.length,
      ];
      const kompOrder = ["D", "P", "T"];
      const startIdx = kompOrder.indexOf(jenisKomp) + 1;
      return kompLenArr.slice(startIdx).reduce((total, num) => total + num) > 0;
    },
    getTagColor(pspd_id, preceptor_id) {
      return this.userId == pspd_id || this.userId == preceptor_id;
    },
    getRawatIcon(jenis) {
      return jenis == "jalan" ? "walking" : "bed";
    },
  },
  mixins: [onlineCheckMixin, paginationMixin],
  activated() {
    if (
      has(this.$route.params, "verifikasi") ||
      has(this.$route.params, "stase")
    ) {
      this.aktivitasListMdl.reset();
      this.filterMap.verifikasi = this.$route.params.verifikasi;
      this.filterMap.stase = this.$route.params.stase;
      this.filterMap.cari = "";
      this.filterPrevMap = clone(this.filterMap);
    }
    this.canVerifikasi =
      this.isPreceptor && this.filterMap.verifikasi == "Belum";
    this.fetchData();
  },
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";

.tanggal {
  font-size: 0.8rem !important;
}

::v-deep .is-grouped-multiline {
  margin-bottom: unset !important;
}

.small-icon {
  vertical-align: middle;
  padding-bottom: 5px;
}
</style>
